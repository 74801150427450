// import { graphql } from "gatsby"
import React from "react"
import {
  ButtonLink,
  Column,
  Container,
  Heading,
  Head,
  Emphasized,
  Icon,
  Paragraph,
  Row,
  SecondarySection,
  Section,
  Spacer,
  Subtitle,
  SvgLayer,
} from "../../components/components"
import CallToAction from "../../widgets/CallToAction/CallToAction"

const Chances = ({}: { data: any }) => {
  return (
    <>
      <Head pagePath="/chancen" />
      <Section>
        <Container>
          <Row>
            <Column style={{ alignItems: "center" }}>
              <Heading tag="h1" style={{ textAlign: "center" }}>
                Die Vorteile der <Emphasized>Digitalisierung</Emphasized>{" "}
              </Heading>
            </Column>
          </Row>
          <Spacer gutter={16} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Paragraph textAlign="center">
              Die Digitalisierung stellt zahlreiche Unternehmen vor enorme
              Herausforderungen. Diese Hürden sind jedoch gleichzeitig auch als
              Chancen zu betrachten. Wenn Sie das Wachstumspotential Ihres
              Unternehmens durch die richtige Software noch nicht erkannt haben,
              helfen Ihnen diese Leitartikel, realisierbare Vorteile für Ihren
              Anwendungsfall zu erkennen.
            </Paragraph>
          </div>
        </Container>
      </Section>
      <SecondarySection>
        <Container>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Column>
              <SvgLayer inverted icon={() => <Icon.Mechanism />}>
                <Subtitle color="purple">VORTEILE</Subtitle>
                <Heading tag="h1">Prozesse optimieren</Heading>
                <Paragraph>
                  Das Wälzen von Ordnern ist weder zeitgemäß noch ökonomisch
                  oder ökologisch. Sparen Sie Zeit, Geld und Nerven, indem Sie
                  bestehende Prozesse optimieren, beschleunigen oder
                  automatisieren. Entlasten Sie Ihr Fachpersonal und
                  konzentrieren Sie Ihre Ressourcen auf das Wachstum Ihres
                  Unternehmens. Erfahren Sie hier, welche Möglichkeiten die
                  Digitalisierung bietet, um ineffiziente Geschäftsabläufe zu
                  optimieren.
                </Paragraph>
                <ButtonLink arrowButton purple to="/chancen/prozessoptimierung">
                  Mehr Erfahren
                </ButtonLink>
              </SvgLayer>
            </Column>
          </Row>
        </Container>
        <Container>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Column>
              <SvgLayer icon={() => <Icon.StudyInfo />}>
                <Subtitle color="red">VORTEILE</Subtitle>
                <Heading tag="h1">Kostenstruktur optimieren </Heading>
                <Paragraph>
                  Schon die Evolutionstheorie besagt, dass nur diejenigen
                  überleben, die sich anzupassen verstehen. Bei stetig
                  wechselnden Anforderungen gestaltet sich das nicht immer
                  einfach. Machen Sie sich die Vorzüge der Digitalisierung
                  zunutze, um Kosten zu senken und sie flexibel zu gestalten, um
                  agil zu bleiben und Risiken zu minimieren. Hier erfahren Sie,
                  wie Sie von der Digitalisierung profitieren können.
                </Paragraph>
                <ButtonLink
                  arrowButton
                  red
                  to="/chancen/kostenstruktur-optimieren"
                >
                  Mehr Erfahren
                </ButtonLink>
              </SvgLayer>
            </Column>
          </Row>
        </Container>
        <Container>
          <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Column>
              <SvgLayer inverted icon={() => <Icon.DataAnalysis />}>
                <Subtitle color="blue">VORTEILE</Subtitle>
                <Heading tag="h1">Wettbewerbsfähigkeit stärken</Heading>
                <Paragraph>
                  In Zeiten der Globalisierung rückt die Welt immer näher
                  zusammen. Was auf der einen Seite eine Vielzahl an Chancen und
                  Möglichkeiten mit sich bringt, bedeutet gleichermaßen
                  wachsende Konkurrenz in allen Branchen. Nutzen Sie die Chance,
                  Ihrer Konkurrenz voraus zu sein - denn wer hinterherläuft,
                  gibt die Zügel aus der Hand. Erfahren Sie hier, wie wir Sie
                  für den Wettbewerb wappnen.
                </Paragraph>
                <ButtonLink
                  arrowButton
                  blue
                  to="/chancen/wettbewerbsfaehigkeit-staerken"
                >
                  Mehr Erfahren
                </ButtonLink>
              </SvgLayer>
            </Column>
          </Row>
        </Container>
      </SecondarySection>
      <CallToAction type="customer" style={{ marginTop: "6rem" }} />
    </>
  )
}

export default Chances
